import React from "react";
import LazyLoad from "react-lazyload";
import Slider from "react-slick";
// import { trainersImages } from "./data";
import { trainersImages } from "../../MLPMainComp/Trainers/data";

import "./styles.scss";

function Trainers() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="mlp-part-time-trainers">
      <div className="will-be-training">
        <div className="trainers-container">
          <h2 className="training-section-title">Who will be training?</h2>
          
          <div className="trainers-slider">
            <Slider {...settings}>
              {trainersImages.map((info, key) => (
                <div className={`trainer-card ${info?.addStyles ? "customized" : ""}`} key={key}>
                  <LazyLoad once>
                    <img className={info?.addStyles ? "customized-trainer-img" : ""} src={info.image} alt={info.alt} title={info.alt} />
                  </LazyLoad>
                </div>
              ))}
            </Slider>
            <div className="trainers-note">And a range of external experts</div>
          </div>
        </div>
      </div>
      {/* <div className="placements-description">
        <h2 className="admission-section-title">WHAT COULD MY CAREER TRAJECTORY LOOK LIKE?</h2>
        <span>
          <StaticImage
            src="https://kfwebassets.s3.ap-south-1.amazonaws.com/common/Group-11278.png"
            alt="title underlines"
            placeholder="none"
          />
        </span>
        <div className="trajectory-image">
          <StaticImage
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yp2ma2.png"
            alt="Digital Marketing Trajectory Image"
            title="Digital Marketing Trajectory"
            placeholder="none"
          />
        </div>
      </div> */}
    </div>
  );
}

export default Trainers;
